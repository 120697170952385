export const StatusReviewDisplayed = Object.freeze({
  NONE: { label: '-', value: null },
  PDF: { label: 'PDF', value: 1 },
  SEARCH: { label: 'Search', value: 2 },
  LISTING: { label: 'Listing', value: 3 },
});

export const statusReviewDisplayedList = [
  ...Object.values(StatusReviewDisplayed),
];

export const getStatusReviewDisplayedByValue = (val = null) => {
  if (val === null) return '';

  return statusReviewDisplayedList.find((el) => el.value === val);
};
