<template>
  <div>
    <vx-card>
      <h5>{{ name }}</h5>

      <div class="mt-4">
        <vs-label text="Available" />
        <vs-switch
          color="primary"
          :value="data.available"
          @input="(val) => save(type, 'available', val)"
        />
      </div>

      <div class="mt-4" v-if="withProgram">
        <vs-label text="Is there any program" />
        <vs-switch
          color="primary"
          :value="data.program"
          @input="(val) => save(type, 'program', val)"
        />
      </div>

      <div class="mt-4" v-if="withQbank">
        <vs-label text="In Qbank" />
        <vs-switch
          color="primary"
          :value="data.qbank"
          @input="(val) => save(type, 'qbank', val)"
        />
      </div>

      <div class="mt-4">
        <vs-label v-if="withProgram" text="Description of program" />
        <vs-label v-if="withQbank" text="Notes" />
        <vs-input
          size="small"
          class="w-full"
          :value="data.description"
          @change="(ev) => save(type, 'description', ev.target.value)"
        />
      </div>

      <div class="mt-4">
        <vs-label text="Review partner" />
        <vs-input
          size="small"
          class="w-full"
          :value="data.partner"
          @change="(ev) => save(type, 'partner', ev.target.value)"
        />
      </div>

      <div class="mt-4" v-if="!reviewsDisplayedSelectable">
        <vs-label text="How are reviews displayed" />
        <vs-input
          size="small"
          class="w-full"
          :value="data.displayed"
          @change="(ev) => save(type, 'displayed', ev.target.value)"
        />
      </div>

      <div class="mt-4" v-if="reviewsDisplayedSelectable">
        <vs-label text="How and where are reviews displayed" />
        <v-select
          :options="statusReviewDisplayedList"
          :clearable="false"
          :value="displayedOption"
          size="small"
          @input="(option) => save(type, 'displayed', option.value)"
        />
      </div>
    </vx-card>
  </div>
</template>

<script>
import vSelect from 'vue-select';

import {
  statusReviewDisplayedList,
  getStatusReviewDisplayedByValue,
} from '@/enums/StatusReviewDisplayed';

export default {
  data: () => ({
    statusReviewDisplayedList,
  }),
  props: {
    name: {
      type: String,
      required: true,
    },
    data: {
      type: Object,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
    withProgram: {
      type: Boolean,
      required: false,
      default: false,
    },
    withQbank: {
      type: Boolean,
      required: false,
      default: false,
    },
    reviewsDisplayedSelectable: {
      type: Boolean,
      required: false,
      default: false,
    },
    save: {
      type: Function,
      required: true,
    },
  },
  components: {
    vSelect,
  },
  computed: {
    displayedOption: {
      get() {
        return getStatusReviewDisplayedByValue(this.data.displayed);
      },
      set(obj) {
        this.data.displayed = obj.value;
      },
    },
  },
};
</script>
