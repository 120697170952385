<template>
  <div class="vx-row">
    <status-review-card
      v-if="statusReview.organic"
      class="vx-col lg:w-1/4 md:w-1/3 sm:w-1/2 w-full mb-6"
      name="Organic"
      type="organic"
      :data="statusReview.organic"
      :save="saveStatusReview"
      with-program
    />

    <status-review-card
      v-if="statusReview.syndicated"
      class="vx-col lg:w-1/4 md:w-1/3 sm:w-1/2 w-full mb-6"
      name="Syndicated"
      type="syndicated"
      :data="statusReview.syndicated"
      :save="saveStatusReview"
      with-program
    />

    <status-review-card
      v-if="statusReview.techProgram"
      class="vx-col lg:w-1/4 md:w-1/3 sm:w-1/2 w-full mb-6"
      name="Tech Program"
      type="techProgram"
      :data="statusReview.techProgram"
      :save="saveStatusReview"
      with-program
    />

    <status-review-card
      v-if="statusReview.badgets"
      class="vx-col lg:w-1/4 md:w-1/3 sm:w-1/2 w-full mb-6"
      name="Badgets"
      type="badgets"
      :data="statusReview.badgets"
      :save="saveStatusReview"
      reviews-displayed-selectable
      with-qbank
    />

    <status-review-card
      v-if="statusReview.awards"
      class="vx-col lg:w-1/4 md:w-1/3 sm:w-1/2 w-full mb-6"
      name="Awards"
      type="awards"
      :data="statusReview.awards"
      :save="saveStatusReview"
      reviews-displayed-selectable
      with-qbank
    />

    <status-review-card
      v-if="statusReview.quotes"
      class="vx-col lg:w-1/4 md:w-1/3 sm:w-1/2 w-full mb-6"
      name="Quotes"
      type="quotes"
      :data="statusReview.quotes"
      :save="saveStatusReview"
      reviews-displayed-selectable
      with-qbank
    />

    <status-review-card
      v-if="statusReview.mediaLogo"
      class="vx-col lg:w-1/4 md:w-1/3 sm:w-1/2 w-full mb-6"
      name="Media Logo"
      type="mediaLogo"
      :data="statusReview.mediaLogo"
      :save="saveStatusReview"
      reviews-displayed-selectable
      with-qbank
    />

    <status-review-card
      v-if="statusReview.other"
      class="vx-col lg:w-1/4 md:w-1/3 sm:w-1/2 w-full mb-6"
      name="Other"
      type="other"
      :data="statusReview.other"
      :save="saveStatusReview"
      reviews-displayed-selectable
      with-qbank
    />
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';

import StatusReviewCard from '@/views/ui-elements/campaign-product/StatusReviewCard';

import { CampaignAction } from '@/store/actionTypes';

export default {
  components: {
    StatusReviewCard,
  },
  computed: {
    ...mapState('campaign', {
      statusReview: ({ statusReview }) => statusReview,
    }),

    campaignId() {
      return parseInt(this.$route.params.id);
    },
  },
  methods: {
    ...mapActions('campaign', [CampaignAction.updateStatusReview]),

    async saveStatusReview(type, field, value) {
      if (this.$gate.can('edit_campaigns')) {
        this.$vs.loading();

        try {
          const obj = {
            id: this.campaignId,
            type,
            field,
            value,
          };

          await this.updateStatusReview(obj);

          this.notifySuccess(
            'Status Review updated',
            'The status review has been successfully updated.'
          );
        } catch (error) {
          this.notifyError(error, 'Problem with updating a status review.');
        }

        this.$vs.loading.close();
      }
    },
  },
};
</script>
