<template>
  <data-list-view-wrapper>
    <h3 class="mb-4" v-show="campaignName">Campaign: {{ campaignName }}</h3>

    <campaign-product-sidebar
      v-if="ableToEditCampaign(campaignUserId)"
      :is-sidebar-active="addNewProductSidebar"
      @close-sidebar="toggleProductDataSidebar"
      :campaignId="campaignId"
    />

    <offline-activity-sidebar
      :is-sidebar-active="addNewOfflineActivitySidebar"
      @close-sidebar="toggleOfflineActivityDataSidebar"
      :data="offlineActivitySidebarData"
    />

    <div class="flex column items-center md:justify-center justify-start mb-6">
      <div class="btn-group">
        <vs-button
          :color="activeTab === 'products' ? 'primary' : 'dark'"
          type="line"
          class="font-medium"
          @click="setActiveTab('products')"
          >Products list</vs-button
        >
        <!-- <vs-button
          :color="activeTab === 'statusReview' ? 'primary' : 'dark'"
          type="line"
          class="font-medium"
          @click="setActiveTab('statusReview')"
          >Status review</vs-button
        > -->
        <vs-button
          :color="activeTab === 'offline' ? 'primary' : 'dark'"
          type="line"
          class="font-medium"
          @click="setActiveTab('offline')"
          >Offline activities</vs-button
        >
        <vs-button
          :color="activeTab === 'summary' ? 'primary' : 'dark'"
          type="line"
          class="font-medium"
          @click="setActiveTab('summary')"
          >Summary</vs-button
        >
      </div>
    </div>

    <!-- PRODUCTS -->
    <vx-table
      ref="tableProducts"
      v-model="selectedProducts"
      search
      :data="products"
      v-show="activeTab === 'products'"
      @selected="configureProduct"
    >
      <div
        v-if="ableToEditCampaign(campaignUserId)"
        slot="header"
        class="flex flex-wrap-reverse items-center flex-grow justify-between"
      >
        <div class="flex flex-wrap-reverse items-center">
          <button-create title="Add product" @click="addNewProduct" />
        </div>
      </div>

      <template slot="thead">
        <vs-th>Image</vs-th>
        <vs-th sort-key="name">Name</vs-th>
        <vs-th sort-key="brandName">Brand</vs-th>
        <vs-th sort-key="categoryName">Category</vs-th>
        <vs-th>Action</vs-th>
      </template>

      <template slot-scope="{ data }">
        <tbody>
          <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
            <vs-td class="img-container">
              <img :src="tr.pictureUrl" class="product-img" />
            </vs-td>

            <vs-td>
              <p class="product-name">{{ tr.name }}</p>
            </vs-td>

            <vs-td>
              <p class="product-brand">{{ tr.brandName }}</p>
            </vs-td>

            <vs-td>
              <p class="product-category">{{ tr.categoryName }}</p>
            </vs-td>

            <vs-td class="whitespace-no-wrap">
              <feather-icon
                v-if="ableToEditCampaign(campaignUserId)"
                icon="TrashIcon"
                svg-classes="w-5 h-5 hover:text-danger stroke-current"
                class="ml-2"
                @click.stop="confirmDeleteProduct(tr.id, tr.name)"
              />
            </vs-td>
          </vs-tr>
        </tbody>
      </template>
    </vx-table>
    <!-- / PRODUCTS -->

    <!-- STATUS REVIEW -->
    <status-review-tab v-show="activeTab === 'statusReview'" />
    <!-- / STATUS REVIEW -->

    <!-- OFFLINE ACTIVITIES -->
    <vx-table
      ref="tableOfflineActivities"
      search
      :data="offlineActivities"
      v-show="activeTab === 'offline'"
    >
      <div
        slot="header"
        class="flex flex-wrap-reverse items-center flex-grow justify-between"
      >
        <div class="flex flex-wrap-reverse items-center">
          <button-create
            v-if="ableToEditCampaign(campaignUserId)"
            title="Add offline activity"
            @click="addNewOfflineActivity"
          />
        </div>
      </div>

      <template slot="thead">
        <vs-th sort-key="activity">Activity</vs-th>
        <vs-th sort-key="budget">Budget</vs-th>
        <vs-th sort-key="start_date">Start date</vs-th>
        <vs-th sort-key="end_date">End date</vs-th>
        <vs-th sort-key="reach">Reach</vs-th>
        <vs-th>Action</vs-th>
      </template>

      <template slot-scope="{ data }">
        <tbody>
          <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
            <vs-td>
              <p class="product-activity">
                {{ tr.activity }}
              </p>
            </vs-td>

            <vs-td>
              <p class="product-budget">{{ tr.budget | title }}</p>
            </vs-td>

            <vs-td>
              <p class="product-start_date">{{ tr.start_date | title }}</p>
            </vs-td>

            <vs-td>
              <p class="product-end_date">{{ tr.end_date | title }}</p>
            </vs-td>

            <vs-td>
              <p class="product-reach">{{ tr.reach | title }}</p>
            </vs-td>

            <vs-td class="whitespace-no-wrap">
              <feather-icon
                v-if="ableToEditCampaign(campaignUserId)"
                icon="SettingsIcon"
                svg-classes="w-5 h-5 hover:text-primary stroke-current"
                @click.stop="editOfflineActivityData(tr)"
              />
              <feather-icon
                v-if="ableToEditCampaign(campaignUserId)"
                icon="TrashIcon"
                svg-classes="w-5 h-5 hover:text-danger stroke-current"
                class="ml-2"
                @click.stop="confirmDeleteOfflineActivity(tr.id, tr.activity)"
              />
            </vs-td>
          </vs-tr>
        </tbody>
      </template>
    </vx-table>
    <!-- / OFFLINE ACTIVITIES -->

    <!-- SUMMARY -->
    <summary-tab v-if="activeTab === 'summary'" :user-id="campaignUserId" />
    <!-- / SUMMARY -->
  </data-list-view-wrapper>
</template>

<script>
import { mapActions, mapState } from 'vuex';

import CampaignProductSidebar from '@/views/sidebars/CampaignProductSidebar';
import OfflineActivitySidebar from '@/views/sidebars/OfflineActivitySidebar';
import SummaryTab from '@/views/ui-elements/campaign/SummaryTab';
import StatusReviewTab from '@/views/ui-elements/campaign/StatusReviewTab';
import DataListViewWrapper from '@/views/wrappers/DataListViewWrapper';
import VxTable from '@/components/vx-table/vxTable';
import ButtonCreate from '@/views/components/buttons/ButtonCreate';

import { CampaignAction } from '@/store/actionTypes';
import moduleCampaign from '@/store/campaigns/moduleCampaign';

import dataListMixin from '@/mixins/dataListMixin';
import manageCampaignMixin from '@/mixins/manageCampaignMixin';

export default {
  mixins: [dataListMixin, manageCampaignMixin],
  components: {
    CampaignProductSidebar,
    OfflineActivitySidebar,
    SummaryTab,
    StatusReviewTab,
    DataListViewWrapper,
    VxTable,
    ButtonCreate,
  },
  data() {
    return {
      campaignName: null,
      campaignUserId: null,
      selectedProducts: [],
      addNewProductSidebar: false,
      addNewOfflineActivitySidebar: false,
      offlineActivitySidebarData: {},
      activeTab: 'products',
    };
  },
  computed: {
    ...mapState('campaign', {
      products: ({ products }) => products,
      offlineActivities: ({ offlineActivities }) => offlineActivities,
    }),

    campaignId() {
      return parseInt(this.$route.params.id);
    },
    queriedProductItems() {
      return this.$refs.tableProducts
        ? this.$refs.tableProducts.queriedResults.length
        : this.products.length;
    },
    queriedOfflineActivityItems() {
      return this.$refs.tableOfflineActivities
        ? this.$refs.tableOfflineActivities.queriedResults.length
        : this.offlineActivities.length;
    },
  },
  methods: {
    ...mapActions('campaign', [
      CampaignAction.fetchOne,
      CampaignAction.fetchProducts,
      CampaignAction.fetchStatusReviews,
      CampaignAction.fetchOfflineActivities,
      CampaignAction.fetchSummary,
      CampaignAction.removeProduct,
      CampaignAction.removeOfflineActivity,
    ]),

    registerStoreModules() {
      if (!moduleCampaign.isRegistered) {
        this.$store.registerModule('campaign', moduleCampaign);
        moduleCampaign.isRegistered = true;
      }
    },
    addNewProduct() {
      if (!this.ableToEditCampaign(this.campaignUserId)) {
        return;
      }

      this.toggleProductDataSidebar(true);
    },
    addNewOfflineActivity() {
      this.offlineActivitySidebarData = {};
      this.toggleOfflineActivityDataSidebar(true);
    },
    editOfflineActivityData(data) {
      this.offlineActivitySidebarData = data;
      this.toggleOfflineActivityDataSidebar(true);
    },
    confirmDeleteProduct(id, name) {
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Confirm Delete',
        text: `Are you sure you want to delete "${name}"?`,
        accept: () => this.deleteProduct(id),
        acceptText: 'Delete',
      });
    },
    async deleteProduct(id) {
      if (!this.ableToEditCampaign(this.campaignUserId)) {
        return;
      }

      this.$vs.loading();

      try {
        await this.removeProduct(id);

        this.notifySuccess(
          'Campaign product deleted',
          'The campaign product has been successfully deleted.'
        );
      } catch (error) {
        this.notifyError(error, 'Problem with deleting a product.');
      }

      this.$vs.loading.close();
    },
    confirmDeleteOfflineActivity(id, name) {
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Confirm Delete',
        text: `Are you sure you want to delete "${name}"?`,
        accept: () => this.deleteOfflineActivity(id),
        acceptText: 'Delete',
      });
    },
    async deleteOfflineActivity(id) {
      this.$vs.loading();

      try {
        await this.removeOfflineActivity(id);

        this.notifySuccess(
          'Offline Activity deleted',
          'The offline activity has been successfully deleted.'
        );
      } catch (error) {
        this.notifyError(error, 'Problem with deleting an offline activity.');
      }

      this.$vs.loading.close();
    },
    configureProduct({ id }) {
      this.$router
        .push({
          name: 'campaign-product-configuration',
          params: { id: this.campaignId, productId: id },
        })
        .catch(() => {});
    },
    toggleProductDataSidebar(val = false) {
      this.addNewProductSidebar = val;
    },
    toggleOfflineActivityDataSidebar(val = false) {
      this.addNewOfflineActivitySidebar = val;
    },
    setActiveTab(tab) {
      this.activeTab = tab;
    },
  },
  async created() {
    this.registerStoreModules();

    this.$vs.loading();

    try {
      const campaign = this.fetchCampaign(this.campaignId);
      const products = this.fetchProducts(this.campaignId);
      const statusReviews = this.fetchStatusReviews(this.campaignId);
      const offlineActivities = this.fetchOfflineActivities(this.campaignId);
      const summary = this.fetchSummary(this.campaignId);

      const data = await Promise.all([
        campaign,
        products,
        statusReviews,
        offlineActivities,
        summary,
      ]);

      const [campaignData] = data;
      const { name, user_id } = campaignData.data;

      this.campaignName = name;
      this.campaignUserId = user_id;
    } catch (error) {
      this.notifyError(error, 'Problem with loading data.');
    }

    this.$vs.loading.close();
  },
};
</script>
