<template>
  <div :class="classes">
    <vx-card
      :title="title"
      :title-color="titleColor"
      :card-background="cardBackground"
      :content-color="contentColor"
    >
      <div class="vx-row">
        <div :class="`${columnClasses}`">
          <p :class="`vs-input--label pl-0 ${labelClass}`" @click="save">
            <b>Budget:</b>
          </p>
          <p>{{ data.budget | money }}$</p>
          <p :class="`vs-input--label pl-0 mt-2 ${labelClass}`">
            <b>Spent:</b>
          </p>
          <p>{{ data.spent | money }}$</p>
          <p :class="`vs-input--label pl-0 mt-2 ${labelClass}`"><b>Diff:</b></p>
          <p>{{ data.budget_diff | percentage }}</p>
        </div>

        <div :class="`${columnClasses}`">
          <p :class="`vs-input--label pl-0 ${labelClass}`">
            <b>Units budget:</b>
          </p>
          <p>{{ data.units_budget | numInt }}</p>
          <p :class="`vs-input--label pl-0 mt-2 ${labelClass}`">
            <b>Units sold:</b>
          </p>
          <p>{{ data.units_sold | numInt }}</p>
          <p :class="`vs-input--label pl-0 mt-2 ${labelClass}`"><b>Diff:</b></p>
          <p>{{ data.units_diff | percentage }}</p>
        </div>

        <vs-divider v-if="!total" />

        <div :class="`${columnClasses}`">
          <p :class="`vs-input--label pl-0 ${labelClass}`">
            <b>Budget revenue:</b>
          </p>
          <p>{{ data.budget_revenue | money }}$</p>
          <p :class="`vs-input--label pl-0 mt-2 ${labelClass}`">
            <b>Revenue:</b>
          </p>
          <p>{{ data.revenue | money }}$</p>
          <p :class="`vs-input--label pl-0 mt-2 ${labelClass}`"><b>Diff:</b></p>
          <p>{{ data.revenue_diff | percentage }}</p>
        </div>

        <div :class="`${columnClasses}`">
          <p :class="`vs-input--label pl-0 ${labelClass}`">
            <b>Planned ROI:</b>
          </p>
          <p>{{ data.planned_roi | percentage }}</p>
          <p :class="`vs-input--label pl-0 mt-2 ${labelClass}`"><b>ROI:</b></p>
          <p>{{ data.roi | percentage }}</p>
          <p :class="`vs-input--label pl-0 mt-2 ${labelClass}`"><b>Diff:</b></p>
          <p>{{ data.roi_diff | percentage }}</p>
        </div>
      </div>
    </vx-card>
  </div>
</template>

<script>
export default {
  props: {
    classes: {
      type: String,
      required: false,
      default: '',
    },
    title: {
      type: String,
      required: false,
      default: '',
    },
    titleColor: {
      type: String,
      required: false,
      default: 'primary',
    },
    cardBackground: {
      type: String,
      required: false,
      default: '#fffdf0',
    },
    contentColor: {
      type: String,
      required: false,
      default: 'greydark',
    },
    labelClass: {
      type: String,
      required: false,
      default: '',
    },
    data: {
      type: Object,
      required: true,
    },
    total: {
      type: Boolean,
      required: false,
      default: false,
    },
    save: {
      type: Function,
      required: false,
      default: () => {},
    },
  },
  computed: {
    columnClasses() {
      return !this.total ? 'vx-col w-1/2 mb-6' : 'vx-col md:w-1/4 w-1/2 mb-6';
    },
  },
};
</script>
