<template>
  <vs-sidebar
    click-not-close
    position-right
    parent="body"
    default-index="1"
    color="primary"
    class="vs-sidebar__wrapper"
    spacer
    v-model="isActive"
  >
    <div class="mt-6 flex items-center justify-between px-6">
      <h4>{{ titleType }} OFFLINE ACTIVITY</h4>
      <feather-icon
        icon="XIcon"
        @click.stop="isActive = false"
        class="cursor-pointer"
      />
    </div>

    <vs-divider class="mb-0" />

    <component
      :is="scrollbarTag"
      :settings="settings"
      class="vs-sidebar__inner"
    >
      <!-- ACTIVITY -->
      <div>
        <vs-label text="Activity" />
        <vs-input
          v-model="dataActivity"
          class="w-full"
          name="activity"
          v-validate="'required'"
        />
        <span class="text-danger text-sm" v-show="errors.has('activity')">
          {{ errors.first('activity') }}
        </span>
      </div>

      <!-- BUDGET -->
      <div class="mt-4">
        <vs-label text="Budget" />
        <vs-input
          v-model="dataBudget"
          class="w-full"
          name="budget"
          type="number"
          step="0.01"
          min="0"
          v-validate="'required'"
        />
        <span class="text-danger text-sm" v-show="errors.has('budget')">
          {{ errors.first('budget') }}
        </span>
      </div>

      <!-- START DATE -->
      <div class="mt-4">
        <vs-label text="Start date" />
        <datepicker
          placeholder="Select Date"
          class="w-full"
          v-model="dataStartDate"
        />
      </div>

      <!-- END DATE -->
      <div class="mt-4">
        <vs-label text="End date" />
        <datepicker
          placeholder="Select Date"
          class="w-full"
          v-model="dataEndDate"
        />
      </div>

      <!-- REACH -->
      <div class="mt-4">
        <vs-label text="Reach" />
        <vs-input
          v-model="dataReach"
          class="w-full"
          name="reach"
          type="number"
          step="1"
          min="0"
        />
      </div>

      <!-- BUTTONS -->
      <sidebar-buttons
        :valid="isFormValid"
        @click="handleSubmit"
        @cancel="handleClose"
      />
    </component>
  </vs-sidebar>
</template>

<script>
import Datepicker from 'vuejs-datepicker';
import { mapActions } from 'vuex';

import { CampaignAction } from '@/store/actionTypes';

import sidebarMixin from '@/mixins/sidebarMixin';

import '@/assets/scss/app/pages/sidebar.scss';

export default {
  mixins: [sidebarMixin],
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    Datepicker,
  },
  data() {
    return {
      dataId: null,
      dataActivity: '',
      dataBudget: 0,
      dataStartDate: new Date(),
      dataEndDate: new Date(),
      dataReach: 0,
    };
  },
  watch: {
    isSidebarActive(val) {
      if (!val) return;

      if (Object.entries(this.data).length === 0) {
        this.initValues();
        this.$validator.reset();
      } else {
        const {
          id,
          activity,
          budget,
          start_date,
          end_date,
          reach,
        } = JSON.parse(JSON.stringify(this.data));

        this.dataId = id;
        this.dataActivity = activity;
        this.dataBudget = budget;
        this.dataStartDate = start_date;
        this.dataEndDate = end_date;
        this.dataReach = reach;

        this.initValues();
      }
    },
  },
  computed: {
    isFormValid() {
      return (
        !this.errors.any() &&
        this.dataActivity &&
        this.dataBudget &&
        this.dataStartDate &&
        this.dataEndDate
      );
    },
    campaignId() {
      return parseInt(this.$route.params.id);
    },
  },
  methods: {
    ...mapActions('campaign', [
      CampaignAction.addOfflineActivity,
      CampaignAction.updateOfflineActivity,
    ]),

    initValues() {
      if (this.data.id) return;

      this.dataId = null;
      this.dataActivity = '';
      this.dataBudget = 0;
      this.dataStartDate = new Date();
      this.dataEndDate = new Date();
      this.dataReach = 0;
    },
    async handleSubmit() {
      const isValid = await this.$validator.validateAll();

      if (!isValid) return;

      const obj = {
        id: this.dataId,
        campaign_id: this.campaignId,
        activity: this.dataActivity,
        budget: this.dataBudget,
        start_date: this.dataStartDate,
        end_date: this.dataEndDate,
        reach: this.dataReach,
      };

      this.$vs.loading();

      if (this.shouldUpdate) {
        try {
          await this.updateOfflineActivity(obj);

          this.notifySuccess(
            'Offline Activity updated',
            'The offline activity has been successfully updated.'
          );
        } catch (error) {
          this.notifyError(error, 'Problem with updating an offline activity.');
        }
      } else {
        delete obj.id;

        try {
          await this.addOfflineActivity(obj);

          this.notifySuccess(
            'Offline Activity added',
            'The offline activity has been successfully added.'
          );
        } catch (error) {
          this.notifyError(error, 'Problem with adding an offline activity.');
        }
      }

      this.$vs.loading.close();
      this.$emit('close-sidebar');
      this.initValues();
    },
  },
};
</script>
