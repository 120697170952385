<template>
  <div :class="classes">
    <vx-card
      :title="title"
      :title-color="titleColor"
      :card-background="cardBackground"
      :content-color="contentColor"
    >
      <div class="vx-row">
        <div class="vx-col w-1/2 mb-6">
          <p :class="`vs-input--label pl-0 ${labelClass}`" @click="save">
            <b>Budget:</b>
          </p>
          <p>{{ data.budget | money }}$</p>
          <p :class="`vs-input--label pl-0 mt-2 ${labelClass}`">
            <b>Spent ($):</b>
          </p>
          <vs-input
            v-model="spentValue"
            class="w-full"
            name="spent"
            type="number"
            step="0.01"
            size="small"
            ref="spentInput"
            @change="handleSave"
            :disabled="!ableToEditCampaign(userId)"
          />
          <p :class="`vs-input--label pl-0 mt-2 ${labelClass}`"><b>Diff:</b></p>
          <p>{{ data.budget_diff | percentage }}</p>
        </div>

        <div class="vx-col w-1/2 mb-6">
          <p :class="`vs-input--label pl-0 ${labelClass}`">
            <b>Units budget:</b>
          </p>
          <vs-input
            v-model="unitsBudgetValue"
            class="w-full"
            name="units_budget"
            type="number"
            step="0.01"
            size="small"
            ref="unitsBudgetInput"
            @change="handleSave"
            :disabled="!ableToEditCampaign(userId)"
          />
          <p :class="`vs-input--label pl-0 mt-2 ${labelClass}`">
            <b>Units sold:</b>
          </p>
          <vs-input
            v-model="unitsSoldValue"
            class="w-full"
            name="units_sold"
            type="number"
            step="0.01"
            size="small"
            ref="unitsSoldInput"
            @change="handleSave"
            :disabled="!ableToEditCampaign(userId)"
          />
          <p :class="`vs-input--label pl-0 mt-2 ${labelClass}`"><b>Diff:</b></p>
          <p>{{ data.units_diff | percentage }}</p>
        </div>

        <vs-divider v-if="!total" />

        <div class="vx-col w-1/2 mb-6">
          <p :class="`vs-input--label pl-0 ${labelClass}`">
            <b>Budget revenue ($):</b>
          </p>
          <vs-input
            v-model="budgetRevenueValue"
            class="w-full"
            name="budget_revenue"
            type="number"
            step="0.01"
            size="small"
            ref="budgetRevenueInput"
            @change="handleSave"
            :disabled="!ableToEditCampaign(userId)"
          />
          <p :class="`vs-input--label pl-0 mt-2 ${labelClass}`">
            <b>Revenue:</b>
          </p>
          <vs-input
            v-model="revenueValue"
            class="w-full"
            name="revenue"
            type="number"
            step="0.01"
            size="small"
            ref="revenueInput"
            @change="handleSave"
            :disabled="!ableToEditCampaign(userId)"
          />
          <p :class="`vs-input--label pl-0 mt-2 ${labelClass}`"><b>Diff:</b></p>
          <p>{{ data.revenue_diff | percentage }}</p>
        </div>

        <div class="vx-col w-1/2 mb-6">
          <p :class="`vs-input--label pl-0 ${labelClass}`">
            <b>Planned ROI:</b>
          </p>
          <p>{{ data.planned_roi | percentage }}</p>
          <p :class="`vs-input--label pl-0 mt-2 ${labelClass}`"><b>ROI:</b></p>
          <p>{{ data.roi | percentage }}</p>
          <p :class="`vs-input--label pl-0 mt-2 ${labelClass}`"><b>Diff:</b></p>
          <p>{{ data.roi_diff | percentage }}</p>
        </div>
      </div>
    </vx-card>
  </div>
</template>

<script>
import manageCampaignMixin from '@/mixins/manageCampaignMixin';

export default {
  mixins: [manageCampaignMixin],
  data: () => ({
    spentValue: 0,
    unitsBudgetValue: 0,
    unitsSoldValue: 0,
    budgetRevenueValue: 0,
    revenueValue: 0,
  }),
  props: {
    classes: {
      type: String,
      required: false,
      default: '',
    },
    userId: {
      type: [Number, null],
      default: null,
    },
    title: {
      type: String,
      required: false,
      default: '',
    },
    titleColor: {
      type: String,
      required: false,
      default: 'primary',
    },
    cardBackground: {
      type: String,
      required: false,
      default: 'fffdf0',
    },
    contentColor: {
      type: String,
      required: false,
      default: 'greydark',
    },
    labelClass: {
      type: String,
      required: false,
      default: '',
    },
    data: {
      type: Object,
      required: true,
    },
    total: {
      type: Boolean,
      required: false,
      default: false,
    },
    spent: {
      type: Number,
      required: true,
    },
    units_budget: {
      type: Number,
      required: true,
    },
    units_sold: {
      type: Number,
      required: true,
    },
    budget_revenue: {
      type: Number,
      required: true,
    },
    revenue: {
      type: Number,
      required: true,
    },
    save: {
      type: Function,
      required: false,
      default: () => {},
    },
  },
  mounted() {
    this.spentValue = this.spent;
    this.unitsBudgetValue = this.units_budget;
    this.unitsSoldValue = this.units_sold;
    this.budgetRevenueValue = this.budget_revenue;
    this.revenueValue = this.revenue;
  },
  methods: {
    handleSave() {
      const obj = {
        spent: this.$refs['spentInput'].value,
        units_budget: this.$refs['unitsBudgetInput'].value,
        units_sold: this.$refs['unitsSoldInput'].value,
        budget_revenue: this.$refs['budgetRevenueInput'].value,
        revenue: this.$refs['revenueInput'].value,
      };

      this.$emit('save', obj);
    },
  },
};
</script>
