<template>
  <vs-sidebar
    click-not-close
    position-right
    parent="body"
    default-index="1"
    color="primary"
    class="vs-sidebar__wrapper"
    spacer
    v-model="isActive"
  >
    <div class="mt-6 flex items-center justify-between px-6">
      <h4>{{ titleType }} PRODUCT</h4>
      <feather-icon
        icon="XIcon"
        @click.stop="isActive = false"
        class="cursor-pointer"
      />
    </div>

    <vs-divider class="mb-0" />

    <component
      :is="scrollbarTag"
      :settings="settings"
      class="vs-sidebar__inner"
    >
      <!-- PRODUCT -->
      <div>
        <vs-label text="Product" />
        <v-select
          v-model="dataProduct"
          :options="productChoices"
          :clearable="false"
        />
      </div>

      <!-- BUTTONS -->
      <sidebar-buttons
        :valid="isFormValid"
        @click="handleSubmit"
        @cancel="handleClose"
      />
    </component>
  </vs-sidebar>
</template>

<script>
import vSelect from 'vue-select';
import { mapActions, mapState } from 'vuex';

import sidebarMixin from '@/mixins/sidebarMixin';

import { CampaignAction, ProductAction } from '@/store/actionTypes';
import moduleProduct from '@/store/products/moduleProduct';

import '@/assets/scss/app/pages/sidebar.scss';

export default {
  mixins: [sidebarMixin],
  props: {
    campaignId: {
      type: Number,
      required: true,
    },
  },
  components: {
    vSelect,
  },
  data() {
    return {
      dataProduct: null,
    };
  },
  watch: {
    isSidebarActive(val) {
      if (!val) return;

      this.initValues();
      this.$validator.reset();
    },
  },
  computed: {
    ...mapState('product', {
      products: ({ products }) => products,
    }),

    isFormValid() {
      return !this.errors.any() && this.dataProduct;
    },

    productChoices() {
      const choices = this.products.map(
        ({ id, name, brandName, categoryName }) => {
          return { id, label: `${name} [${categoryName} - ${brandName}]` };
        }
      );

      return choices;
    },
  },
  methods: {
    ...mapActions('campaign', [CampaignAction.addProduct]),
    ...mapActions('product', [ProductAction.fetch]),

    registerStoreModules() {
      if (!moduleProduct.isRegistered) {
        this.$store.registerModule('product', moduleProduct);
        moduleProduct.isRegistered = true;
      }
    },
    initValues() {
      this.dataProduct = null;
    },
    async handleSubmit() {
      const isValid = await this.$validator.validateAll();

      if (!isValid) return;

      const obj = {
        campaignId: this.campaignId,
        productId: this.dataProduct.id,
      };

      this.$vs.loading();

      try {
        await this.addProduct(obj);

        this.notifySuccess(
          'Campaign product added',
          'The campaign product has been successfully added.'
        );
      } catch (error) {
        this.notifyError(error, 'Problem with adding a product.');
      }

      this.$vs.loading.close();
      this.$emit('close-sidebar');
      this.initValues();
    },
  },
  async created() {
    this.registerStoreModules();

    this.$vs.loading();

    try {
      await this.fetchProducts();
    } catch (error) {
      this.notifyError(error, 'Problem with loading products.');
    }

    this.$vs.loading.close();
  },
};
</script>
